import React from "react"
import { Outlet } from "react-router-dom"
import HeaderComponent from "./components/header.component"
import FooterComponent from "./components/footer.component"

const MainLayout:React.FC = () => {

  const testingClass:string= " bg-red-500 xs:bg-amber-500 sm:bg-yellow-500 md:bg-green-500 lg:bg-blue-500"

  return (
    <div className="main-layout">
      <div className="layout-wrapper">
        <HeaderComponent />
        <main id="main" className={"main pb-1 pt-16"} role="main">
          <Outlet />
        </main>
        <FooterComponent />
      </div>
    </div>
  )

}

export default MainLayout