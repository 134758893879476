import React from "react"
import LogoComponent from "../../shared/components/logo.component"
import { menuItemsData } from "../../shared/data/menuItems.data"
import NavigationComponent from "./navigation.component"
import I81nComponent from "./i18n.component"
import { RiMenuFold2Line } from "react-icons/ri";
import DrawerComponent from "./drawer.component"
import focus from "./../../assets/media/logo/focus.svg"
import SearchComponent from "./search.component"

const HeaderComponent: React.FC = () => {

  const [isOpen, setIsOpen] = React.useState(false)
  const [showMegaMenu, setShowMegaMenu] = React.useState(false)
  const [showSearch, setShowSearch] = React.useState(false)

  React.useEffect(() => {
    const onResize = () => {
      if(window.innerWidth >= 1024) {
        setIsOpen(false)
        setShowSearch(false)
      }
    }
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    }
  }, []);

  return (
    <div className="header fixed top-0 left-0 right-0 z-30">
      <div className={"header-wrapper relative min-h-[70px] border-b bg-[#333] px-2 flex items-center "+(showMegaMenu?"border-focus-primary":"border-white")}>
        <div className="inner px-7 flex flex-1 items-center justify-between">
          <LogoComponent name="Focus" type="svg" src={focus} path="/" />
          <NavigationComponent setShowMegaMenu={setShowMegaMenu} showMegaMenu={showMegaMenu} navigation={menuItemsData} className="flex-1 ml-auto justify-end w-full hidden lg:flex xl:mr-5" />
          <I81nComponent />
          <SearchComponent setShowSearch={setShowSearch} showSearch={showSearch} className="xl:ml-5" />
          <RiMenuFold2Line onClick={() => setIsOpen(!isOpen)} className="fill-white text-2xl block lg:hidden" />
          <DrawerComponent isOpen={isOpen} setIsOpen={(open: boolean) => setIsOpen(open)} />
        </div>
      </div>
    </div>
  )

}

export default HeaderComponent